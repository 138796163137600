<template>
  <iframe class="web-browser" :src="appObj.extra.url" ></iframe>
</template>

<script>
export default {
  name: 'WebBrowser',
  props: {
    appObj: Object,
  }
}
</script>
<style>
.web-browser {
  width: 100%;
  height: 100%;
}
</style>
