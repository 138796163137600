<template>
  <div id="clockBar">
    <div id="currentTime" @click="toggleClock">{{ time }}</div>

    <div id="currentDate">
      <transition name="slide-fade">
        <div v-show="showDate == true" class="innerwrapper">
          <div id="fullTime">{{ fullTime }}</div>
          <div id="todayDate">{{ todayDate }}</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Clock",
  data() {
    return {
      time: "",
      fullTime: "",
      todayDate: ""
    };
  },
  mounted: function() {
    this.displayTime();
    this.displayDate();
  },
  methods: {
    displayTime() {
      var _vue = this;
      setInterval(function() {
        _vue.time = new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit"
        });
        _vue.fullTime = new Date().toLocaleTimeString();
      }, 1000);
    },
    displayDate() {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };
      this.todayDate = new Date().toLocaleDateString("nl-NL", options);
    },
    toggleClock() {
      console.log("toggle");
      this.$store.commit("toggleClock", !this.showDate); // send opposite of showDate from computed method in order to toggle
    }
  },
  computed: {
    showDate() {
      return this.$store.state.expandClock;
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#clockBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  padding: 0 20px 0 10px;
  color: #ffffff;
  width: 100px;
  #currentTime {
    padding: 4px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    height: 100%;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
#currentDate {
  position: absolute;
  bottom: 40px;
  right: 0;
  overflow: hidden;
  .innerwrapper {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  #fullTime {
    font-size: 2.5rem;
    font-weight: 300;
  }
  #todayDate {
    font-size: 1rem;
    padding: 5px 0 0;
    color: #00a2ed;
  }
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  // set animation during activity
  transition: all 0.3s; //opacity 0.5s, transform 0.5s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  // leaving state
  opacity: 0;
  transform: translateY(100px);
}
.slide-fade-enter-active {
  // animation during entering
  opacity: 0;
  transform: translateY(100px);
}
.slide-fade-enter-to {
  // last step of entering
  opacity: 1;
  transform: translateY(0);
}
</style>
