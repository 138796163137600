import { createStore } from "vuex";

export default createStore({
  state: {
    desktop_icons: [
      {
        id: "about-me",
        name: "About me",
        alt: "",
        icon: "folder.png",
        type: "folder",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: ""
        }
      },
      {
        id: "dev-vids",
        name: "Development",
        alt: "",
        icon: "folder.png",
        type: "folder",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: "about-me"
        }
      },
      // {
      //   id: "cv-dd",
      //   name: "CV Dieuwert",
      //   alt: "",
      //   icon: "pdf.png",
      //   type: "pdf",
      //   status: "",
      //   zindex: 0,
      //   extra: {
      //     file: "cv dieuwert daantje4.pdf",
      //     parentFolder: "about-me"
      //   }
      // },
      {
        id: "portfolio-html",
        name: "Portfolio",
        alt: "",
        icon: "fox.png",
        type: "webpage",
        status: "",
        zindex: 0,
        extra: {
          url: "https://www.dieuwertdaantje.nl/portfolio/",
          parentFolder: "about-me"
        }
      },
      {
        id: "linkedIn",
        name: "LinkedIn Profile",
        alt: "",
        icon: "linkedin.svg",
        type: "linkedin",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: "about-me"
        }
      },
      {
        id: "fav-vids",
        name: "Favorite video's",
        alt: "",
        icon: "folder.png",
        type: "folder",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: "about-me"
        }
      },
      {
        id: "videonpc",
        name: "Joel Haver - Tailing NPC's",
        alt: "",
        icon: "video.png",
        type: "video",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: "fav-vids",
          url: "https://www.youtube.com/watch?v=iP468OEln4U"
        }
      },
      {
        id: "videocrowd",
        name: "IT Crowd - Thank you party",
        alt: "",
        icon: "video.png",
        type: "video",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: "fav-vids",
          url: "https://www.youtube.com/watch?v=ka7l_QGtEZE"
        }
      },
      {
        id: "magazine",
        name: "Digital Magazine",
        alt: "",
        icon: "video.png",
        type: "video",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: "dev-vids",
          url: "https://www.youtube.com/watch?v=_0kQ2RnFJDw"
        }
      },
      {
        id: "mailsystem",
        name: "Mailsystem",
        alt: "",
        icon: "video.png",
        type: "video",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: "dev-vids",
          url: "https://www.youtube.com/watch?v=2WFAvBe5tEs"
        }
      },
      {
        id: "osSpecs",
        name: "About OS",
        alt: "",
        icon: "os.svg",
        type: "os",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: "startmenu"
        }
      },
      {
        id: "wAmp",
        name: "Winamp",
        alt: "",
        icon: "winamp.png",
        type: "winamp",
        status: "",
        zindex: 0,
        extra: {
          parentFolder: ""
        }
      },
      {
        id: "alleycat",
        name: "Alley Cat",
        alt: "Alley Cat",
        icon: "alleycat.png",
        type: "game",
        status: "",
        zindex: 0,
        extra: {
          gfolder: "games",
          file: "CAT.jsdos",
          parentFolder: ""
        }
      },
      {
        id: "wolfenstein",
        name: "Wolfenstein 3D",
        alt: "Wolfenstein 3D",
        icon: "wolf3d.png",
        type: "game",
        status: "",
        zindex: 0,
        extra: {
          gfolder: "games",
          file: "WOLF3D.jsdos",
          parentFolder: ""
        }
      },
      {
        id: "outrun",
        name: "Outrun",
        alt: "Outrun",
        icon: "outrun.png",
        type: "game",
        status: "",
        zindex: 0,
        extra: {
          gfolder: "games",
          file: "OUTRUN.jsdos",
          parentFolder: ""
        }
      },
      {
        id: "princeofpersia",
        name: "Prince of Persia",
        alt: "Prince of Persia",
        icon: "pop.png",
        type: "game",
        status: "",
        zindex: 0,
        extra: {
          gfolder: "games",
          file: "POP.jsdos",
          parentFolder: ""
        }
      },
      {
        id: "simcity",
        name: "Sim City",
        alt: "Sim City",
        icon: "simcity.png",
        type: "game",
        status: "",
        zindex: 0,
        extra: {
          gfolder: "games",
          file: "SIMCITY.jsdos",
          parentFolder: ""
        }
      }
    ],
    active_windows: [],
    windowZindex: 1,
    currentWindowId: "",
    focusedExecutable: "",
    expandSubmenu: false,
    expandClock: false,
    rebootState: false
  },
  mutations: {
    createWindow(state, arr) {
      // set new Z index for new window
      state.windowZindex++;
      // check if a window with the same instance is already active
      var findActiveWindow = state.active_windows.filter(x => x.id == arr.id);
      // limit window of the same application to 1
      if (findActiveWindow.length < 1) {
        arr.zindex = state.windowZindex;
        state.active_windows.push(arr);
      }
    },
    removeWindow(state, removeId) {
      for (var i = state.active_windows.length - 1; i >= 0; --i) {
        if (state.active_windows[i].id == removeId) {
          state.active_windows.splice(i, 1);
        }
      }
    },
    changeWindowStatus(state, { obj, newStatus }) {
      var findObject = state.active_windows.filter(x => x.id === obj.id); // find the object that needs updating
      findObject[0].status = newStatus; // update status with param value
      var updatedObject = findObject[0]; // set new var name for updated variable and to also get rid of index calling in findObject
      var index = state.active_windows.findIndex(
        x => x.id === updatedObject.id
      ); // get index of the object that needs updating for splice
      state.active_windows.splice(index, 1, updatedObject); // update array with updatedObject
    },
    windowToTop(state, obj) {
      if (obj != null) {
        state.windowZindex++; // raise z index for use on the next window that needs to be on top
        state.currentWindowId = obj.id; // set id of current Window at top
        var findObject = state.active_windows.filter(x => x.id === obj.id); // find the object that needs updating
        findObject[0].zindex = state.windowZindex; // update status with param value
        var updatedObject = findObject[0]; // set new var name for updated variable and to also get rid of index calling in findObject
        var index = state.active_windows.findIndex(
          x => x.id === updatedObject.id
        ); // get index of the object that needs updating for splice
        state.active_windows.splice(index, 1, updatedObject); // update array with updatedObject
      }
    },
    createGame(state, { id, gameURL }) {
      // eslint-disable-next-line
      emulators.pathPrefix = "js-dos/";
      var commandInterfaceID = "ci" + id; // Define an ID for Command Interface of game app.
      // eslint-disable-next-line
      window[commandInterfaceID] = Dos(document.getElementById(id)).run(gameURL);
    },
    closeGame(state, id) {
      // eslint-disable-next-line
      var commandInterfaceID = 'cijsdos' + id; // Define an ID for Command Interface of game app.
      // eslint-disable-next-line
      window[commandInterfaceID].then(ci => {
        ci.exit(); // remove DOS game instance
      });
    },
    focusExecutable(state, id) {
      state.focusedExecutable = id;
    },
    toggleSubmenu(state, boolean) {
      state.expandSubmenu = boolean;
    },
    toggleClock(state, boolean) {
      state.expandClock = boolean;
    },
    initiateReboot(state) {
      state.rebootState = true;
    }
  },
  actions: {},
  modules: {}
});
