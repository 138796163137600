<template>
  <transition name="fade">
    <div
      class="winamp-wrapper"
      :style="'z-index:' + windowProcess.zindex"
      v-if="windowProcess"
      v-show="windowProcess.status != 'minimized'"
      id="winamp2-js"
      @mousedown="putWindowToTop()"
    ></div>
  </transition>
</template>

<script>
import Winamp from "winamp2-js";

export default {
  name: "WinAmp",
  props: {
    windowProcess: Object
  },
  mounted: function() {
    this.putWindowToTop(); // place at top of all other window when component is loaded
    this.renderWinamp(); // call instance of WinAmp module
    this.resetWindowStatus(); // making sure it does not stay minimized when rendered after refreshing page. (small bug)
  },
  methods: {
    async renderWinamp() {
      const winamp = new Winamp({
        initialTracks: [
          {
            metaData: {
              artist: "Ragnarok Online",
              title: "Cheongchoon"
            },
            url: "winamp/mp3/ragnarok-online-cheongchoon.mp3"
          },
          {
            metaData: {
              artist: "Street Fighter V",
              title: "Alex' theme"
            },
            url: "winamp/mp3/sfv-alex.mp3"
          },
          {
            metaData: {
              artist: "Megaman X 5",
              title: "Duff McWhalen Theme"
            },
            url: "winamp/mp3/mmx5-duff-mcwhalen.mp3"
          }
        ],
        initialSkin: {
          url: "winamp/skin/classic.wsz"
        },
        availableSkins: [
          { url: "winamp/skin/Donkey_Kong.wsz", name: "Donkey Kong" },
          { url: "winamp/skin/Dragon_Ball_Z.wsz", name: "Dragon Ball Z" },
          { url: "winamp/skin/Final_Fantasy_VII.wsz", name: "Final Fantasy 7" },
          { url: "winamp/skin/Mega_Man_X.wsz", name: "Mega Man X" },
          { url: "winamp/skin/RX-178_Gundam_MK2.wsz", name: "Gundam MKII" },
          { url: "winamp/skin/Super_Mario.wsz", name: "Super Mario" },
          { url: "winamp/skin/Super_Metroid.wsz", name: "Super Metroid" }
        ]
      });
      await winamp.renderWhenReady(document.getElementById("winamp2-js")); // render WinAmp first before going to next line
      document
        .getElementById("minimize")
        .addEventListener("click", this.minimizeWindow); // Apply listener to a div (for minimizing) in WinAmp application. Without this, there is not minimize function.
      document
        .getElementById("close")
        .addEventListener("click", this.removeWindow); // Apply listener to a div (for close) in WinAmp application. In order to remove component instance.
      document
        .getElementById("option")
        .addEventListener("click", this.addExitButton); // Apply listener to a div (for close) in WinAmp application. In order to remove component instance.
    },
    putWindowToTop: function() {
      this.$store.commit("windowToTop", this.windowProcess);
    },
    minimizeWindow: function() {
      this.$store.commit("changeWindowStatus", {
        obj: this.windowProcess,
        newStatus: "minimized"
      });
    },
    removeWindow: function() {
      this.$store.commit("removeWindow", this.windowProcess.id); // Close container window.
    },
    addExitButton: function() {
      // Add exit button to window menu (left side) build in winAmp module
      var _vue = this; // to keep scope used in setTimeOut
      setTimeout(function() {
        if (document.getElementById("context-menu")) {
          // this element ID appears after click
          var exitButton = document.querySelectorAll('li[label="Exit"]');
          exitButton[0].addEventListener("click", _vue.removeWindow); // add method removeWindow() on click to this element.
        }
      }, 150);
    },
    resetWindowStatus: function() {
      this.$store.commit("changeWindowStatus", {
        obj: this.windowProcess,
        newStatus: ""
      });
    }
  }
};
</script>
<style lang="scss">
// must loose scoped in style tag or most of the following css will not be applied. Mainly to WinAmp module.
.winamp-wrapper {
  position: relative;
  #context-menu li[label="Exit"] {
    //display: none !important;
  }
  // fix
  #winamp2-js #marquee {
    height: 14px;
  }
}
</style>
