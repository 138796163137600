<template>
  <div
    class="executable-wrapper"
    :class="[focusExecutable === execObj.id ? 'active' : '', execObj.id]"
    @dblclick="openApp"
    @click="clickedExecutable"
  >
    <div class="executable-icon">
      <!-- eslint-disable-next-line -->
      <img :src="require('@/assets/' + execObj.icon)" :alt="execObj.alt">
    </div>
    <div class="executable-label">
      {{ execObj.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Executable",
  props: {
    execObj: Object
  },
  methods: {
    openApp: function() {
      if (this.execObj.type == "redirect") {
        window.open(this.execObj.extra.url, "_blank");
      } else {
        this.$store.commit("createWindow", this.execObj);
      }
    },
    clickedExecutable: function() {
      if (window.matchMedia("(max-device-width: 620px)").matches) {
        if (this.execObj.type == "redirect") {
          window.open(this.execObj.extra.url, "_blank");
        } else {
          this.$store.commit("createWindow", this.execObj);
        }
      } else {
        this.$store.commit("focusExecutable", this.execObj.id);
      }
    }
  },
  computed: {
    focusExecutable() {
      return this.$store.state.focusedExecutable;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$exec-width: 100px;
$icon-width: 64px;
.executable-wrapper {
  padding: 3px 10px;
  margin: 0 0 6px;
  max-width: $exec-width;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-flow: column wrap;
  border: 1px solid transparent;
  border-radius: 2px;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.1);
  }
  &.active {
    border: 1px solid rgba(173, 216, 230, 0.4) !important;
    background-color: rgba(173, 216, 230, 0.3) !important;
  }
  .executable-icon {
    max-width: $icon-width;
    padding: 0 10px;
    margin: 0 auto;
    img {
      display: block;
      width: 100%;
    }
  }
  .executable-label {
    padding: 4px 0 0;
    max-width: 88px; //100px;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 2px 2px #111111;
    font-size: 0.75rem;
  }
}
// icon coloring
.executable-wrapper {
  &.linkedIn {
    img {
      display: block;
      width: 40px;
      margin: 2px;
      border-radius: 4px;
      overflow: hidden;
      background-color: #ffffff;
    }
  }
}
</style>
