<template>
  <div class="terminal-program">
    <!-- eslint-disable-next-line -->
    <div :id="'jsdos' + appObj.id" width="300" height="300" class="screen-canvas"></div>
    <!--<canvas id="jsdos" width="300" height="300" @click="runGame('AlleyCat.zip', 'AlleyCat', 'cat.exe')"></canvas>-->
  </div>
</template>

<script>
export default {
  name: "Screen",
  props: {
    appObj: Object
  },
  data() {
    return {
      canvasIdNumber: Math.floor(Math.random() * 9999999) + 1
    };
  },
  mounted: function() {
    var gameId = "jsdos" + this.appObj.id;
    var gamePath = new String(this.appObj.extra.gfolder + '/' + this.appObj.extra.file);
    console.log(gameId + " + " + gamePath);
    this.$store.commit("createGame", {id: gameId, gameURL: gamePath,});
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dosbox-container {
  height: 100%;
}
.terminal-program {
  flex-grow: 1;
}
.screen-canvas {
  height: 100%;
}
.emulator-canvas {
  max-width: 100%;
}
</style>
