<template>
  <div id="LinkedInProfile">
    <div id="LinkedInBar">
      <div id="LinkedInBarInner">
        <img :src="require('@/assets/linkedin.svg')" />
      </div>
    </div>
    <div id="LI-inner">
      <div id="LI-header">
        <div id="LI-data">
          <div id="LI-name">Dieuwert Daantje</div>
          <div id="LI-jobdesc">Internet Professional</div>
          <div id="LI-location">The Randstad, Netherlands</div>
        </div>
        <div class="linkedin-button">
          <a
            href="https://www.linkedin.com/pub/dieuwert-daantje/25/702/593"
            target="_blank"
          >
            View Profile on Linkedin
          </a>
        </div>
      </div>
      <div id="LI-experience">
        <div id="LI-jobs" class="li-block">
          <div id="LI-expTitle" class="job-education-title">
            Experience
          </div>
          <div v-for="job in jobslist" :key="job" class="job">
            <div class="company-icon">
              <img :src="require('@/assets/li-profile/' + job.image)" />
            </div>
            <div class="job-description">
              <div class="job-title">{{ job.title }}</div>
              <div class="job-company">{{ job.company }}</div>
              <div class="job-period">
                {{ job.period }}
                <span v-if="job.duration">{{ job.duration }}</span>
              </div>
              <div class="job-location" v-if="job.location">
                {{ job.location }}
              </div>
              <div class="job-tasks" v-if="job.tasks" v-html="job.tasks"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="LI-education" class="li-block">
        <div class="job-education-title">
          Education
        </div>
        <div class="job">
          <div class="company-icon">
            <img :src="require('@/assets/li-profile/hu.jpg')" />
          </div>
          <div class="job-description">
            <div class="job-title">Hogeschool van Utrecht</div>
            <div class="job-company">
              Digitale Communicatie, Communication & Multimedia Design
            </div>
            <div class="job-period">2006 - 2010</div>
            <div class="job-location">Utrecht</div>
            <div class="job-tasks">Behaald</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkedInProfile",
  data() {
    return {
      jobslist: [
        {
          title: "Frontend Developer",
          company: "Vicrea Solutions",
          period: "May 2022 - Present",
          duration: "",
          location: "Amersfoort",
          image: "vicrea.jpg",
          tasks: ""
        },
        {
          title: "Frontend Developer",
          company: "Creative Result Communication",
          period: "Jun 2014 - Apr 2022",
          duration: "7 years 11 months",
          location: "Leusden",
          image: "crc.png",
          tasks: ""
        },
        {
          title: "Webmaster",
          company: "Selamat Jalan Tour",
          period: "Feb 2014 - Feb 2016",
          duration: "2 years 1 month",
          location: "Leusden",
          image: "unavailable.svg",
          tasks: ""
        },
        {
          title: "Webbouwer",
          company: "SiteSpirit",
          period: "Mar 2011 - Mar 2014",
          duration: "3 years 1 month",
          location: "Baarn",
          image: "ss.jpg",
          tasks:
            "• Bouw van nieuwe websites in CMS van Sitespirit (HTML, CSS, Javascript)<br>• Cursussen geven aan klanten voor gebruik van het CMS.<br>• Aanpassen van huidige websites op verzoek van klanten. Zoals nieuwe functionaliteiten of wijzigingen in bestaande modules.<br>• Domeinregistratie, DNS wijzigingen doorvoeren.<br>• Telefonisch/mail support voor websites en mail<br>• PDF templates van Facturen/Reistickets/Vouchers/etc bouwen, in CRM met Smarty"
        },
        {
          title: "Gebruikersondersteuner",
          company: "OGD ict-diensten",
          period: "Oct 2010 - Feb 2011",
          duration: "5 months",
          location: "Utrecht",
          image: "ogd.png",
          tasks:
            "• Gebruikersondersteuner bij Ministerie van Infrastructuur en Milieu (op locaties Utrecht, Zeist en Den Dolder)<br>• Gebruikersondersteuner Outlook bij Provincie Utrecht"
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.linkedin-button {
  display: flex;
  align-items: flex-end;
  margin: 10px 0 0;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 240px;
    min-width: 220px;
    border-radius: 24px;
    background-color: #0073b1;
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    line-height: 2;
    &:hover {
      background-color: #004182;
    }
  }
}
#LinkedInBar {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  padding: 0 20px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px -1px 1px rgb(0 0 0 / 8%), 1px 0px 1px rgb(0 0 0 / 8%),
    -1px 0px 1px rgb(0 0 0 / 8%), 0px 1px 1px rgb(0 0 0 / 8%);
  #LinkedInBarInner {
    max-width: 1024px;
    //padding: 0 20px 10px;
    margin: 4px auto;
  }
  img {
    max-width: 40px;
    padding: 5px 0;
    width: 100%;
    display: block;
  }
}
#LinkedInProfile {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  background-color: #ffffff;
  #LI-inner {
    max-width: 1024px;
    margin: 0 auto;
    padding: 56px 0 0;
    position: relative;
    z-index: 1;
  }
  #LI-header {
    position: relative;
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #LI-name,
  .job-education-title,
  .job-title {
    font-size: 1.3rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.9);
  }
  .job-education-title {
    font-weight: 400;
  }
  #LI-jobdesc,
  #LI-location,
  .job-company {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.6;
  }
  #LI-jobdesc {
    color: rgba(0, 0, 0, 0.9);
  }
  #LI-location,
  .job-period,
  .job-location {
    color: rgba(0, 0, 0, 0.6);
  }
  #LI-experience {
    padding: 16px;
    border-bottom: 1px solid #dddddd;
  }
}
.li-block {
  padding: 20px 0;
  .job {
    display: flex;
    padding: 24px 0 0 8px;
    .job-title {
      line-height: 1.4;
    }
    .job-company {
      padding: 0 0 6px;
    }
    .job-period,
    .job-location {
      font-weight: 300;
    }
    .job-period {
      padding-bottom: 10px;
    }
    .job-tasks {
      padding: 14px 0 0;
      font-weight: 300;
    }
  }
  .company-icon {
    width: 56px;
    min-width: 56px;
    padding: 6px 10px 0 0;
    img {
      max-width: 100%;
      display: block;
    }
  }
}
</style>
