<template>
  <div class="explorer-wrapper">
    <div class="explorer-file" v-for="item in folderFiles" :key="item">
      <Executable :execObj="item" />
    </div>
  </div>
</template>

<script>
import Executable from "./../../Executable.vue";

export default {
  name: "FileExplorer",
  props: {
    appObj: Object
  },
  components: {
    Executable
  },
  data() {
    return {

    };
  },
  mounted: function() {

  },
  computed: {
    folderFiles() {
      var files = this.$store.state.desktop_icons.filter(x => x.extra.parentFolder == this.appObj.id); // condition is only met when records in array have their parentFolder set to this folder object.
      return files;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.explorer-wrapper {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
</style>
