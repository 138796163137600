<template>
  <transition name="fade">
    <div
      :style="
        'z-index:' +
          windowProcess.zindex +
          ';transform:translate(' +
          posX +
          'px, ' +
          posY +
          'px);width:' +
          width +
          'px;height:' +
          height +
          'px;'
      "
      v-show="windowProcess.status != 'minimized' && show == true"
      :id="'container-' + windowProcess.id"
      class="window-wrapper"
      :class="[
        'container-' + windowProcess.id,
        'type-' + windowProcess.type,
        windowStatus,
        currentWindowId === windowProcess.id ? 'active' : '',
        windowDragging === true ? 'dragging' : '',
        windowResizing === true ? 'resizing' : ''
      ]"
      @mousedown="putWindowToTop()"
      :data-x="posX"
      :data-y="posY"
    >
      <div class="window-top-drag-handle"></div>
      <div class="window-wrapper-inner">
        <div class="window-bar">
          <div class="window-controls">
            <div
              class="window-control window-close"
              @click="removeWindow"
            ></div>
            <div
              class="window-control window-minimize"
              @click="minimizeWindow(windowProcess)"
            ></div>
            <div
              class="window-control window-maximize"
              @click="maximizeWindow"
            ></div>
          </div>
        </div>
        <div class="window-screen">
          <div
            :id="'overlay-' + windowProcess.id"
            class="window-resizing-overlay"
          ></div>
          <GameScreen
            :appObj="windowProcess"
            v-if="windowProcess.type == 'game'"
          />
          <FileExplorer
            :appObj="windowProcess"
            v-if="windowProcess.type == 'folder'"
          />
          <PDFViewer
            :appObj="windowProcess"
            v-if="windowProcess.type == 'pdf'"
          />
          <WebBrowser
            :appObj="windowProcess"
            v-if="windowProcess.type == 'webpage'"
          />
          <SpecsOS :appObj="windowProcess" v-if="windowProcess.type == 'os'" />
          <LinkedIn
            :appObj="windowProcess"
            v-if="windowProcess.type == 'linkedin'"
          />
          <VidPlyr :appObj="windowProcess" v-if="windowProcess.type == 'video'" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import interact from "interactjs";
import GameScreen from "./Screens/Game.vue";
import FileExplorer from "./Screens/FileExplorer.vue";
import PDFViewer from "./Screens/PDFViewer.vue";
import WebBrowser from "./Screens/WebBrowser.vue";
import SpecsOS from "./Screens/SpecsOS.vue";
import LinkedIn from "./Screens/LinkedInProfile.vue";
import VidPlyr from "./Screens/Video.vue";

export default {
  name: "Container",
  components: {
    GameScreen,
    FileExplorer,
    PDFViewer,
    WebBrowser,
    SpecsOS,
    LinkedIn,
    VidPlyr
  },
  props: {
    windowProcess: Object
  },
  mounted: function() {
    this.initDrag();
    this.putWindowToTop();
    this.show = true; // needed to trigger transition animation (set in template) when loading in the first time
    if (
      this.windowProcess.type === "pdf" ||
      this.windowProcess.type === "linkedin"
    ) {
      this.width = 860;
      this.height = 500;
    } else if (this.windowProcess.type == "webpage") {
      this.width = 900;
      this.height = 500;
    } else if (this.windowProcess.type == "os") {
      this.width = 400;
      this.height = 320;
    } else if (this.windowProcess.type == "video") {
      this.width = 618;
      this.height = 367;
    }
    if (window.matchMedia("(max-device-width: 620px)").matches) {
      this.width = 400;
      this.height = 400;
      this.posX = 10;
      this.posY = 10;
    }
  },
  data() {
    return {
      show: false,
      windowId: this.windowProcess.id,
      windowStatus: "",
      windowZindex: 0,
      windowDragging: false,
      windowResizing: false,
      posX: 200,
      posY: 150,
      width: 600,
      height: 260
    };
  },
  methods: {
    putWindowToTop: function() {
      this.$store.commit("windowToTop", this.windowProcess);
    },
    maximizeWindow: function() {
      if (this.windowStatus == "") {
        this.windowStatus = "maximize";
      } else {
        this.windowStatus = "";
      }
    },
    minimizeWindow: function(obj) {
      this.$store.commit("changeWindowStatus", {
        obj: obj,
        newStatus: "minimized"
      });
    },
    removeWindow: function() {
      if (this.windowProcess.type == "game") {
        this.$store.commit("closeGame", this.windowProcess.id); // close game by calling function to close Command Interface of DOS Application
      }
      this.$store.commit("removeWindow", this.windowProcess.id); // Close container window.
    },
    initDrag: function() {
      var _vue = this; // save reference to any data or function set in component
      var className = ".container-" + this.windowProcess.id; // couldn't use one class, because vue would stick to one window
      interact(className)
        .draggable({
          allowFrom: ".window-bar",
          listeners: {
            move(event) {
              // when window is not maximized
              if (!event.target.classList.contains("maximize")) {
                _vue.posX =
                  (parseFloat(event.target.getAttribute("data-x")) || 0) +
                  event.dx;
                _vue.posY =
                  (parseFloat(event.target.getAttribute("data-y")) || 0) +
                  event.dy;

                // update the position attributes
                event.target.setAttribute("data-x", _vue.posX);
                event.target.setAttribute("data-y", _vue.posY);
                _vue.windowDragging = true; // when true > adds class 'dragging'
              }
            }
          }
        })
        .resizable({
          edges: {
            top: ".window-top-drag-handle",
            left: true,
            bottom: true,
            right: true
          },
          listeners: {
            move: function(event) {
              // when window is not maximized
              if (!event.target.classList.contains("maximize")) {
                // set width and height directly
                _vue.width = event.rect.width;
                _vue.height = event.rect.height;

                // save following for data sets
                let { x, y } = event.target.dataset;

                x = (parseFloat(x) || 0) + event.deltaRect.left;
                y = (parseFloat(y) || 0) + event.deltaRect.top;

                _vue.posX = x; //+= event.deltaRect.left;
                _vue.posY = y; //+= event.deltaRect.top;

                event.target.setAttribute("data-x", x);
                event.target.setAttribute("data-y", y);

                //event.target.classList.add("resizing"); // add class when resizing window
                _vue.windowResizing = true; // when true > adds class 'resizing'
                _vue.$store.commit("windowToTop", _vue.windowProcess);
              }
            }
          }
        })
        .on("resizeend", function(event) {
          if (!event.target.classList.contains("maximize")) {
            // when window is not maximized
            _vue.windowResizing = false; // when false > removes class 'resizing'
          }
        })
        .on("dragend", function(event) {
          if (!event.target.classList.contains("maximize")) {
            // when window is not maximized
            _vue.windowDragging = false; // when false > removes class 'dragging'
          }
        });
    }
  },
  computed: {
    currentWindowId() {
      return this.$store.state.currentWindowId;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  // set animation during activity
  transition: all 0.5s; //opacity 0.5s, transform 0.5s;
  .window-wrapper-inner {
    transition: all 0.2s; //opacity 0.5s, transform 0.5s;
  }
}
.fade-enter,
.fade-leave-to {
  // leaving state
  opacity: 0;
  .window-wrapper-inner {
    transform: scale(0.1);
  }
}
.fade-enter-active {
  // animation during entering
  opacity: 0;
  .window-wrapper-inner {
    transform: scale(0.1);
  }
}
.fade-enter-to {
  // last step of entering
  opacity: 1;
  .window-wrapper-inner {
    transform: scale(1);
  }
}

$icon-size: 8px;
$control-size: 14px;
$bar-height: 20px;
.window-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 320px;
  width: 600px;
  min-height: 260px;
  height: 260px;
  padding: 6px;
  touch-action: none; //  to prevent the browser from panning when the user drags with a touch pointer
  user-select: none; // to disable text selection when dragging window
  &.maximize {
    transform: translate(0, 0) !important;
    width: 100% !important;
    height: calc(100% - 40px) !important; //100% !important;
    cursor: default !important;
    padding: 0;
    .window-top-drag-handle {
      display: none;
    }
  }
  &.dragging .window-resizing-overlay,
  &.resizing .window-resizing-overlay {
    display: block !important;
  }
  &.type-pdf {
    height: 500px;
    padding-bottom: 3px;
  }
  .window-top-drag-handle {
    position: absolute;
    width: 100%;
    height: 10px;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .window-wrapper-inner {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #acacac;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border-radius: 6px;
    background-color: #111111;
    padding-top: $bar-height;
  }
  .window-bar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: $bar-height;
    display: flex;
    justify-content: flex-start;
    color: #4d494d;
    background: linear-gradient(to top, #ebebeb, #d5d5d5);
    cursor: default;
  }
  .window-screen {
    position: relative;
    z-index: 1;
    display: flex;
    flex-grow: 1;
    height: 100%;
    .window-resizing-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      display: none;
    }
  }
  .window-controls {
    display: flex;
    justify-content: space-between;
    margin: 0 4px;
    .window-control {
      width: $control-size;
      height: $control-size;
      border-radius: 40px;
      position: relative;
      margin: 2px 2px;
      &:hover {
        &:before,
        &:after {
          display: block;
        }
      }
      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .window-minimize,
    .window-close,
    .window-maximize {
      &:before {
        content: "";
        width: $icon-size;
        border-top: 2px solid #000000;
      }
      &:before,
      &:after {
        display: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .window-maximize,
    .window-close {
      &:after {
        content: "";
        height: $icon-size;
        border-left: 2px solid #000000;
      }
    }
    .window-minimize {
      background-color: #ffbd4c;
      border: 1px solid #e09e3e;
      &:before {
        border-color: #9a5518;
      }
    }
    .window-close {
      background-color: #ff5c5c;
      border: 1px solid #e33e41;
      transform: rotate(45deg);
      &:before,
      &:after {
        border-color: #820005;
      }
    }
    .window-maximize {
      background-color: #00ca56;
      border: 1px solid #14ae46;
      &:before,
      &:after {
        border-color: #006519;
      }
    }
  }
}
</style>
