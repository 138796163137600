<template>
  <div class="plyr__video-embed video-screen" :id="appObj.id">
    <iframe
      :src="appObj.extra.url"
      allowfullscreen
      allowtransparency
      allow="autoplay"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "Video",
  props: {
    appObj: Object
  },
  mounted: function() {
    // eslint-disable-next-line
    const player = new Plyr('#' + this.appObj.id);
  }
}
</script>
<style lang="scss">
</style>
