<template>
  <div id="taskbar">
    <div id="startmenu">
      <div id="startbutton" @click="toggleSubmenu()">
        <img id="starticon" :src="require('@/assets/os-logo.svg')" />
      </div>
      <div id="startSubmenu">
        <transition name="slide-fade">
          <div id="submenuInner" v-show="showSubmenu == true">
            <template v-for="item in startmenuOptions" :key="item">
              <div class="submenu-item" @click="openApp(item)">
                <img
                  class="submenu-icon"
                  :src="require('@/assets/' + item.icon)"
                />
                {{ item.name }}
              </div>
            </template>
            <div class="submenu-item" @click="initiateReboot()">
              <img
                class="submenu-icon"
                :src="require('@/assets/os-restart.svg')"
              />
              Restart
            </div>
            <!--<div class="submenu-item"><a href="."><img class="submenu-icon" :src="require('@/assets/os-restart.svg')" /> Restart</a></div>-->
          </div>
        </transition>
      </div>
    </div>
    <div id="tab-list">
      <div
        :id="'tab-' + item.id"
        class="tab-item"
        :class="[item.status, currentWindowId === item.id ? 'active' : '']"
        v-for="item in activeWindows"
        :key="item"
        @click="focusOnWindow(item)"
      >
        <div class="tab-border"></div>
        <div class="tab-icon">
          <img :src="require('@/assets/' + item.icon)" :alt="item.alt" />
        </div>
        <div class="tab-label">{{ item.name }}</div>
      </div>
    </div>
    <Clock />
  </div>
</template>

<script>
import Clock from "./Clock.vue";

export default {
  name: "Taskbar",
  components: {
    Clock
  },
  methods: {
    focusOnWindow(obj) {
      // if status is minimized, we change that status to default
      if (obj.status == "minimized") {
        this.$store.commit("windowToTop", obj);
        return this.$store.commit("changeWindowStatus", {
          obj: obj,
          newStatus: ""
        });
      } else if (obj.id != this.currentWindowId) {
        this.$store.commit("windowToTop", obj);
      } else {
        this.$store.commit("windowToTop", null);
        return this.$store.commit("changeWindowStatus", {
          obj: obj,
          newStatus: "minimized"
        });
      }
    },
    openApp: function(obj) {
      // opens a new window based on obj
      this.$store.commit("createWindow", obj);
      this.$store.commit("toggleSubmenu", false); // send opposite of showDate from computed method in order to toggle
    },
    toggleSubmenu() {
      this.$store.commit("toggleSubmenu", !this.showSubmenu); // send opposite of showDate from computed method in order to toggle
    },
    initiateReboot() {
      this.$store.commit("initiateReboot"); // send opposite of showDate from computed method in order to toggle

      sessionStorage.setItem("bootUpOS", JSON.stringify(true)); // set cookie to check on after page is refreshed.
      setTimeout(function() {
        window.location.reload();
      }, 2000);
    }
  },
  computed: {
    activeWindows() {
      return this.$store.state.active_windows;
    },
    currentWindowId() {
      if (this.$store.state.currentWindowId) {
        return this.$store.state.currentWindowId;
      } else {
        return "";
      }
    },
    showSubmenu() {
      return this.$store.state.expandSubmenu;
    },
    startmenuOptions() {
      var startmenuOptions = this.$store.state.desktop_icons.filter(
        x => x.extra.parentFolder == "startmenu"
      ); // get all records that are from the start menu
      return startmenuOptions;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$startmenu-width: 46px;
#taskbar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: flex-start;
  height: 40px;
  z-index: 999999;
  #startmenu {
    display: flex;
    width: $startmenu-width;
    #startbutton {
      display: flex;
      align-items: center;
      padding: 0 10px 0 15px;
      color: #ffffff;
      transition: background-color 0.3s;
      &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.2);
        #starticon {
          filter: invert(63%) sepia(58%) saturate(6056%) hue-rotate(169deg)
            brightness(98%) contrast(101%);
        }
      }
      &.active {
        background-color: rgba(255, 255, 255, 0.3);
      }
      #starticon {
        filter: invert(90%) sepia(99%) saturate(26%) hue-rotate(257deg)
          brightness(107%) contrast(100%);
        width: 20px;
      }
    }
    #startSubmenu {
      position: fixed;
      bottom: 40px;
      left: 0px;
      padding: 0;
      overflow: hidden;
      #submenuInner {
        background-color: rgba(0, 0, 0, 0.7);
      }
      .submenu-item {
        display: flex;
        align-items: center;
        padding: 8px 13px;
        color: #ffffff;
        a {
          color: #ffffff;
          text-decoration: none;
          display: inherit;
        }
        &:hover {
          cursor: pointer;
          background-color: rgba(255, 255, 255, 0.2);
        }
        .submenu-icon {
          width: 24px;
          padding-right: 4px;
          //filter: invert(90%) sepia(99%) saturate(26%) hue-rotate(257deg) brightness(107%) contrast(100%);
        }
      }
    }
  }
  #tab-list {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 8px;
    margin: 0 -4px;
    width: calc(100% - #{$startmenu-width + 100}); // 100px clockbar
    .tab-item {
      position: relative;
      height: 100%;
      padding: 0 6px;
      display: flex;
      align-items: center;
      margin: 0 4px;
      color: #ffffff;
      transition: background-color 0.2s;
      overflow: hidden;
      .tab-border {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 3px;
        background-color: #add8e6;
        transition: width 0.3s;
      }
      .tab-icon {
        width: 24px;
        padding: 0 6px 0 0;
        display: flex;
        img {
          width: 100%;
        }
      }
      .tab-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.minimized {
        .tab-border {
          width: 90%;
        }
        &.active {
          background-color: rgba(255, 255, 255, 0) !important;
          .tab-border {
            width: 90%;
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.2) !important;
            .tab-border {
              width: 100%;
            }
          }
        }
      }
      &.active {
        background-color: rgba(255, 255, 255, 0.3) !important;
        .tab-border {
          width: 100%;
        }
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        .tab-border {
          width: 100%;
        }
        cursor: pointer;
      }
    }
  }
}
#tab-linkedIn .tab-icon img {
  background-color: #ffffff;
  border-radius: 4px;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  // set animation during activity
  transition: all 0.3s; //opacity 0.5s, transform 0.5s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  // leaving state
  opacity: 0;
  transform: translateY(100px);
}
.slide-fade-enter-active {
  // animation during entering
  opacity: 0;
  transform: translateY(100px);
}
.slide-fade-enter-to {
  // last step of entering
  opacity: 1;
  transform: translateY(0);
}
</style>
