<template>
  <transition name="fade">
    <div id="osWrapper" v-show="rebootState == false && bootUpOS == false">
      <template v-for="wItem in activeWindows" :key="wItem">
        <Window :windowProcess="wItem" v-if="wItem.type != 'winamp'" />
        <WinAmp :windowProcess="wItem" v-if="wItem.type == 'winamp'" />
      </template>
      <template v-for="item in desktopIcons" :key="item">
        <Executable :execObj="item" />
      </template>
      <Taskbar />
    </div>
  </transition>
  <transition name="fade">
    <div id="rebootContainer" class="boot-container" v-if="rebootState == true">
      <div class="inner-container">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div id="rebootMessage" class="boot-message">
          Restarting...
        </div>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div id="bootUp" class="boot-container" v-if="bootUpOS == true">
      <div class="inner-container">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div id="bootUpMessage" class="boot-message">
          Welcome back
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Window from "./components/Window/Container.vue";
import WinAmp from "./components/Window/WinAmp.vue";
import Executable from "./components/Executable.vue";
import Taskbar from "./components/Taskbar/Taskbar.vue";

export default {
  name: "App",
  components: {
    Window,
    Executable,
    Taskbar,
    WinAmp
  },
  created: function() {
    if (JSON.parse(sessionStorage.getItem("bootUpOS")) == true) {
      this.bootUpOS = true;
      this.initBootUpOS();
    } else {
      this.bootUpOS = false;
    }
  },
  mounted: function() {
    var _vue = this;
    window.addEventListener("click", function(e) {
      var clock = document.getElementById("clockBar");
      var startmenu = document.getElementById("startmenu");
      if (!clock.contains(e.target)) {
        _vue.$store.commit("toggleClock", false); // send opposite of showDate from computed method in order to toggle
      }
      if (!startmenu.contains(e.target)) {
        _vue.$store.commit("toggleSubmenu", false); // toggle
      }
    });
  },
  data() {
    return {
      bootUpOS: false
    };
  },
  methods: {
    initBootUpOS() {
      var _vue = this; // save scope
      setTimeout(function() {
        _vue.bootUpOS = false;
        sessionStorage.setItem("bootUpOS", JSON.stringify(false));
      }, 2000);
    }
  },
  computed: {
    desktopIcons() {
      var desktopIcons = this.$store.state.desktop_icons.filter(
        x => x.extra.parentFolder == ""
      ); // get all records that have no parentFolder
      return desktopIcons;
    },
    activeWindows() {
      return this.$store.state.active_windows;
    },
    rebootState() {
      return this.$store.state.rebootState;
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

*,
*:before,
*::after {
  box-sizing: border-box;
}
* {
  user-select: none;
}
html,
body,
#app {
  height: 100%;
}
html,
body {
  overflow: hidden;
}
body {
  margin: 0;
  position: relative;
  background-color: rgba(11, 29, 52, 1);
}
#app {
  font-family: "Roboto", sans-serif; //Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}
#osWrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  padding: 0 0 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1646%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient1647)'%3e%3c/rect%3e%3cpath d='M1440 0L816.19 0L1440 80.22z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M816.19 0L1440 80.22L1440 267L627.6800000000001 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M627.6800000000001 0L1440 267L1440 287.85L574.6500000000001 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M574.6500000000001 0L1440 287.85L1440 370.19000000000005L495.6700000000001 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L617.59 560L0 405.86z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 405.86L617.59 560L830.49 560L0 269.93z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 269.93L830.49 560L1128.13 560L0 105z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 105L1128.13 560L1178.8500000000001 560L0 67.64z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1646'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='84.72%25' y1='139.29%25' x2='15.28%25' y2='-39.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1647'%3e%3cstop stop-color='rgba(14%2c 42%2c 71%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(62%2c 114%2c 181%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
  // rgba(62, 114, 181, 1)
}
.boot-container {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  .inner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .boot-message {
      color: #ffffff;
      font-size: 1.5rem;
      padding: 10px 0 0;
    }
  }
}
#rebootContainer {
  opacity: 0.9;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1824%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient1825)'%3e%3c/rect%3e%3cpath d='M1440 0L816.19 0L1440 80.22z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M816.19 0L1440 80.22L1440 267L627.6800000000001 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M627.6800000000001 0L1440 267L1440 287.85L574.6500000000001 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M574.6500000000001 0L1440 287.85L1440 370.19000000000005L495.6700000000001 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L617.59 560L0 405.86z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 405.86L617.59 560L830.49 560L0 269.93z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 269.93L830.49 560L1128.13 560L0 105z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 105L1128.13 560L1178.8500000000001 560L0 67.64z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1824'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='84.72%25' y1='139.29%25' x2='15.28%25' y2='-39.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1825'%3e%3cstop stop-color='rgba(14%2c 42%2c 71%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(32%2c 52%2c 77%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
}
#bootUp {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1032%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(24%2c 70%2c 117%2c 1)'%3e%3c/rect%3e%3cpath d='M -648.4224202071973%2c59 C -360.42%2c120 215.58%2c360.2 791.5775797928027%2c364 C 1367.58%2c367.8 2101.89%2c69.2 2231.5775797928027%2c78 C 2361.26%2c86.8 1598.32%2c342 1440%2c408' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3cpath d='M -1568.1910359155424%2c289 C -1280.19%2c256.8 -704.19%2c91.6 -128.19103591554244%2c128 C 447.81%2c164.4 998.17%2c443.8 1311.8089640844576%2c471 C 1625.45%2c498.2 1414.36%2c305.4 1440%2c264' stroke='rgba(51%2c 121%2c 194%2c 0.58)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1032'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  .inner-container {
    opacity: 0.9;
  }
}
.plyr {
  position: absolute;
  width: 100%;
  height: 100%;
}
.lds-roller {
  display: inline-block;
  //position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
