<template>
  <iframe class="pdfvwr" height="100%" width=100% :src="`lib/pdfjs/web/viewer.html?file=` + appObj.extra.file" ></iframe>
</template>

<script>
export default {
  name: 'PDFJSViewer',
  props: {
    appObj: Object,
  }
}
</script>
<style scoped>
iframe.pdfvwr {
  position: absolute;
  top:0;
  left:0;
}
</style>
